import Footer from "./Footer";
import Navbar from "./Navbar";
import ArticleBodyJanTwentyFirst2023 from "./articles/01-21-23";
import ArticleBodyJuneTwentith2023 from "./articles/06-20-23";
import ArticleBodyNovemberThird2023 from "./articles/11-23-23";
import ArticleBodyMayTwentyNinth2024 from "./articles/05-29-2024";


function JanTwentyFirst2023(){
    return (
        <div className="bg-background">
        <Navbar/>
        <ArticleBodyJanTwentyFirst2023/>
        <Footer/>
        </div>
    );
}

function JuneTwentith2023(){
    return (
        <div className="bg-background">
        <Navbar/>
        <ArticleBodyJuneTwentith2023/>
        <Footer/>
        </div>
    )
}

function NovemberThird2023(){
    return (
        <div className="bg-background">
        <Navbar/>
        <ArticleBodyNovemberThird2023/>
        <Footer/>
        </div>
    )
}


function MayTwentyNinth2024(){
    return (
        <div className="bg-background">
        <Navbar/>
        <ArticleBodyMayTwentyNinth2024/>
        <Footer/>
        </div>
    )
}

export {JanTwentyFirst2023, JuneTwentith2023, NovemberThird2023, MayTwentyNinth2024}